import React from "react";
import "./Competences.css";


function Competences (){
return(
    <div className="competences">
        <div className="wrap">
   <div className="section-title wow fadeInUp">
     <h1>Compétences Informatiques :</h1>
     <span className="border"></span>
   </div>
   <div className="row">
     <ul>
       <li className="html">
         <em>HTML/HTML5</em>
         <div>
           <span className="animated fadeInLeft"></span>
         </div>
       </li>
       <li className="css">
         <em>CSS/CSS3</em>
         <div>
           <span className="animated fadeInLeft"></span>
         </div>
       </li>
       <li className="javascript">
         <em>Javascript</em>
         <div>
           <span className="animated fadeInLeft"></span>
         </div>
       </li>
       <li className="reactjs">
         <em>ReactJs</em>
         <div>
           <span className="animated fadeInLeft"></span>
         </div>
       </li>
     </ul>
   </div>
   <div className="row">
     <ul>
       <li className="reactstrap">
         <em>Pack Office</em>
         <div>
           <span className="animated fadeInLeft"></span>
         </div>
       </li>
       <li className="adobe">
         <em>Adobe Illustrator</em>
         <div>
           <span className="animated fadeInLeft"></span>
         </div>
       </li>
     </ul>
   </div>
 </div>
</div>
)           
}

export default Competences; 