const people = [
    {
      id: 1,
      image:
        "Logo/music.jpg",
      name: "Musique",
    },

    {
      id: 2,
      image:
        "Logo/randonne(1).jpg",
      name: "Randonnées",
    },
    {
      id: 3,
      image:
        "Logo/gaming-france.jpg",
      name: "Gaming",
    },

    {
      id: 4,
      image:
        "Logo/photo.jpg",
      name: "Photographie",
    }
  ];
  
  export default people;