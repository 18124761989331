import React from "react";
import Interets2 from "../Interets2/Interets2";


function Interet2 () {
    return(
        <div >
            <Interets2 />
        </div>
    )
}

export default Interet2;